// npm
import React, { memo } from 'react'
import { Box, BoxProps, styled } from '@mui/material'

interface BorderStyleProps {
  mountColor: 'primary.light'
}

interface Props extends BorderStyleProps, BoxProps {
  children: React.ReactNode
}

const Border = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'breakpoint',
})<BorderStyleProps>(({ mountColor, theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  borderRadius: '50%',
  borderWidth: '4px',
  borderStyle: 'solid',
  ...(mountColor === 'primary.light' && {
    borderColor: theme.palette.primary.light,
  }),
  boxShadow: `0 0 0 1px ${theme.palette.primary.dark}`,
  width: '60px',
  height: '60px',
}))

const Frame = styled('div')({
  height: 0,
  paddingTop: '100%',
  position: 'relative',
  background: 'blue',
})

const Inner = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  img: {
    borderRadius: '50% !important',
  },
})

const RoundFrame = ({ children, ...props }: Props) => (
  <Border {...props}>
    <Frame>
      <Inner>{children}</Inner>
    </Frame>
  </Border>
)

export default memo(RoundFrame)
