// npm
import React, { memo } from 'react'
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image'
import {
  Box,
  BoxProps,
  Container,
  Divider,
  styled,
  useTheme,
} from '@mui/material'

// components
import RoundFrame from '@atoms/media/RoundFrame'

// images
import Quote from '@images/icons/quote.svg'

export interface SectionQuoteProps extends BoxProps {
  quote: string
  quotee: string
  secondaryQuote?: string
  organisation?: string
  image: IGatsbyImageData
}

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
  textAlign: 'center',
}))
const QuoteIcon = styled(Quote)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.spacing(2),
  marginLeft: 'auto',
  marginRight: 'auto',
}))

const SectionQuote = ({
  quote,
  quotee,
  secondaryQuote,
  organisation,
  image,
  ...props
}: SectionQuoteProps) => {
  const theme = useTheme()
  return (
    <Wrapper component="section" {...props}>
      <Container maxWidth="md">
        <QuoteIcon />
        <Box
          sx={{
            fontFamily: theme.typography.fontFamilySerif,
            fontWeight: theme.typography.fontWeightThin,
            fontStyle: 'italic',
            fontSize: {
              xs: '3.6rem',
              sm: '4.0rem',
              md: '4.5rem',
              lg: '5.5rem',
              xl: '5.5rem',
            },
          }}
          mb={1}
        >
          “{quote}”
        </Box>
        <Divider
          sx={{
            width: '1px',
            height: secondaryQuote ? '80px' : '60px',
            margin: '0 auto',
          }}
        />
        {secondaryQuote && (
          <>
            <Box typography="leadTextSmall" my={1} lineHeight={1.6}>
              “{secondaryQuote}”
            </Box>
            <Divider
              sx={{
                width: '1px',
                height: secondaryQuote ? '80px' : '60px',
                margin: '0 auto',
              }}
            />
          </>
        )}
        <RoundFrame mountColor="primary.light" mx="auto" my={2}>
          <GatsbyImage image={image} alt="" />
        </RoundFrame>
        <Box mt={1} typography="smallTextSerif" lineHeight="1.7">
          {quotee}
          {organisation && (
            <>
              <br />
              {organisation}
            </>
          )}
        </Box>
      </Container>
    </Wrapper>
  )
}

export default memo(SectionQuote)
